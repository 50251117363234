import React from 'react';

const Home = React.lazy(() => import('./pages/Home'));
const Premium = React.lazy(() => import('./pages/Premium'));
const PremiumResourceInfo = React.lazy(() => import('./pages/PremiumResourceInfo'));
const PremiumWorkShop = React.lazy(() => import('./pages/PremiumWorkShop'));
const ResourceInfo = React.lazy(() => import('./pages/ResourceInfo'));
// const ResourceInfoState = React.lazy(() => import('./pages/ResourceInfoState'));
const WorkShop = React.lazy(() => import('./pages/WorkShop'));
const ProfileEdit = React.lazy(() => import('./pages/ProfileEdit'));
const ChangePassword = React.lazy(() => import('./pages/ChangePassword'));
const PaymentMethod = React.lazy(() => import('./pages/PaymentMethod'));
const ViewMore = React.lazy(() => import('./pages/ViewMore'));
const SearchResult = React.lazy(() => import('./pages/SearchResult'));
const Course = React.lazy(() => import('./pages/Course'));
const Cart = React.lazy(() => import('./pages/Cart'));
const CartDetail = React.lazy(() => import('./pages/SaveCard'));
const CourseName = React.lazy(() => import('./pages/CourseName'));
const CheckOut = React.lazy(() => import('./pages/CheckOut'));
const TermsCondition = React.lazy(() => import('./pages/TermsCondition'));

const routes = [
    // Home Route
    { path: '/', exact: true, name: 'Home', component: Home },
    //Without passing state from Link
    { path: '/resource-info/:id/:type/:isDynamic', exact: true, name: 'Resource Info', component: ResourceInfo },
    //with passing state from link
    // { path: '/resource-info', exact: true, name: 'Resource Info', component: ResourceInfoState },

    { path: '/workshop/:wid/:subid/:isDynamic', exact: true, name: 'Work Shop', component: WorkShop },
    { path: '/profile-Edit', exact: true, name: 'Profile Edit', component: ProfileEdit },
    { path: '/change-password', exact: true, name: 'Change Password', component: ChangePassword },
    { path: '/payment-method', exact: true, name: 'Payment Method', component: PaymentMethod },

    { path: '/search-result', exact: true, name: 'Search Result', component: SearchResult },
    { path: '/view-all-result', exact: true, name: 'View All Result', component: ViewMore },
    // { path: '/search-result/:type/:id/:searchValue', exact: true, name: 'Search Result', component: SearchResult },

    { path: '/premium', exact: true, name: 'Premium', component: Premium },
    { path: '/premium-resource-info/:resourceId', exact: true, name: 'Premium Resource Info', component: PremiumResourceInfo },
    { path: '/premium-workshop/:resourceId', exact: true, name: 'Work Shop', component: PremiumWorkShop },

    { path: '/course-detail/:resourceId', exact: true, name: 'Course', component: Course },
    // { path: '/course-detail/:resourceId', exact: true, name: 'Course', component: Course },

    { path: '/cart', exact: true, name: 'Cart', component: Cart },
    { path: '/add-card-details', exact: true, name: 'CartDetail', component: CartDetail },
    { path: '/add-card-details/:type', exact: true, name: 'CartDetail', component: CartDetail },
    { path: '/add-card-details/:type/:rId', exact: true, name: 'CartDetail', component: CartDetail },
    { path: '/courseName', exact: true, name: 'CourseName', component: CourseName },
    { path: '/checkout/:type', exact: true, name: 'CheckOut', component: CheckOut },
    { path: '/checkout/:type/:rId', exact: true, name: 'CheckOut', component: CheckOut },

    { path: '/terms-and-condition', exact: true, name: 'Terms and Condition', component: TermsCondition },
]

export default routes;