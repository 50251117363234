import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import Button from '@material-ui/core/Button';
import "../scss/pages/signin.scss";
import { reduxForm, Field } from 'redux-form';
import { renderInputField } from '../components/common/FormField';
import { signin as validate } from '../validate/validate';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { forgotPassword } from '../Redux/login/Action';
import { toAbsoluteUrl } from '../utils';

const TITLE = "NJAAL :: Forgot Password"

const ForgotPassword = ({ handleSubmit }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const isLoginState = useSelector(state => state.login.isLoggedIn)
  const [isLoading, setIsLoading] = useState(false);

  const onFormSubmit = (data) => {
    dispatch(forgotPassword(data))
      .then(res => {
        setIsLoading(false)
        if (res.status === 200) {
          swal("", res.message, "success")
          history.push('/signin')
        } else {
          swal("", res.message, "error")
        }
      })
      .catch(err => {
        setIsLoading(false)
        if (err.response.data.message) {
          swal("", err.response.data.message, "error")
        }
        else {
          swal("", 'Please try again', "error")
        }
      })
  }

  if (isLoginState)
    return <Redirect to="/" />

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <section className="accountsec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 forgot-password login-box d-flex align-items-center justify-content-center flex-column">
                <div className="acc-header-top forgot-header d-flex justify-content-between">
                  <Link to='/signin'><img src={toAbsoluteUrl("/images/np_arrow_white_back.svg")} alt="" /></Link>
                </div>
                <div className="acc-header forgot-header">
                  <h3 className="logintitle">Forgot Password</h3>
                </div>
                <div className="login-box-inner">
                  <ul>
                    <li className="form-first-line">
                      <span></span>
                    </li>
                    <Field name="email"
                      type="email"
                      component={renderInputField}
                      placeholder="Email"
                      required />
                  </ul>
                </div>
                <div className="acc-footer">
                  <Button disabled={isLoading} className="btn" type="submit">Submit</Button>
                </div>
              </div>
              <div className="col-md-6 login-box-content d-flex align-items-center justify-content-center" style={{ backgroundImage: `url(${toAbsoluteUrl('/images/signin_background.png')})` }}>
                <div className="login-content-inner">
                  <img src={`${toAbsoluteUrl('/images/signin_forground.png')}`} alt="logo" />
                  {/* <h3>ENGAGE</h3>
                  <h3>EQUIP</h3>
                  <h3>ENCOURAGE</h3> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </>
  )
}
export default reduxForm({
  form: 'forgotPassword',
  validate
})(ForgotPassword)