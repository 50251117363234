import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import Button from '@material-ui/core/Button';
import "../scss/pages/signin.scss";
import { reduxForm, Field } from 'redux-form';
import { renderInputField } from '../components/common/FormField';
import { signin as validate } from '../validate/validate';
import { signIn } from '../Redux/login/Action';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { toAbsoluteUrl, uniqueidGenerator } from "../utils";

const TITLE = "NJAAL :: Sign In"

const SignIn = ({ handleSubmit, match }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const isLoginState = useSelector(state => state.login.isLoggedIn)
  const [isLoading, setIsLoading] = useState(false);

  const onFormSubmit = (data) => {
    dispatch(signIn({ ...data, deviceType: 'web', deviceId: uniqueidGenerator() }))
      .then(res => {
        setIsLoading(false)
        if (res.status === 200) {
          // swal("", res.message, "success")
          match.params.path ? history.push(`/${match.params.path}`) : history.push(`/`)
        } else {
          swal("", res.message, "error")
        }
      })
      .catch(err => {
        setIsLoading(false)
        if (err.response.data.message) {
          swal("", err.response.data.message, "error")
        }
        else {
          swal("", 'Please try again', "error")
        }
      })
  }

  if (isLoginState)
    return <Redirect to="/" />

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <section className="accountsec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 login-box account-signin">
                <div className="login-content login-content-small d-flex align-items-center justify-content-center flex-column">
                  <div className="acc-header-top d-flex justify-content-between">
                    <Link to='/'><img src={toAbsoluteUrl("/images/close-btn-img.svg")} alt="" height={30} /></Link>
                  </div>
                  <div className="acc-header">
                    <h3 className="logintitle">Get Started</h3>
                  </div>
                  <div className="login-box-inner">
                    <ul>
                      <Field name="email"
                        type="text"
                        component={renderInputField}
                        placeholder="Email"
                        required />
                      <Field name="password"
                        type="password"
                        component={renderInputField}
                        placeholder="Password"
                        required />
                      <Link to="/forgot-password" style={{ color: 'black' }}>
                        <li className="form-group align-right">
                          <p>Forgot Password?</p>
                        </li>
                      </Link>
                    </ul>
                  </div>
                  <div className="acc-footer">
                    <Button disabled={isLoading} className="btn" type="submit">Sign In</Button>
                    <p className="form-bottom-line">Don’t Have an Account? <Link to='/create-account' style={{ color: 'black' }}><span>Get Started Now</span></Link></p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 account-signin-img login-box-content d-flex align-items-center justify-content-center" style={{ backgroundImage: `url(${toAbsoluteUrl('/images/signin_background.png')})` }}>
                <div className="login-content-inner">
                  <img src={`${toAbsoluteUrl('/images/signin_forground.png')}`} alt="logo" />
                  {/* <h3>ENGAGE</h3>
                  <h3>EQUIP</h3>
                  <h3>ENCOURAGE</h3> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </>
  )
}
export default reduxForm({
  form: 'signin',
  validate
})(SignIn)